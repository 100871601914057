<template>
    <div class="menu-display view content-padding">
        <div class="header">
            <HeaderLabel
                :label-buttons="labelButtons"
                :buttons-list="buttonsList"
                :separate-buttons="[]"
                :title="`${labels.menu_title}: `"
                :entity-label="menuTitle"
            />
        </div>
        <BaseCard>
            <div v-if="Object.keys(singleEntity).length" class="fields">
                <div class="field">
                    <div v-if="typeof localValues.display.logo == 'string' && localValues.display.logo.length" class="preview">
                        <label>
                            {{ labels.logo }}
                        </label>
                        <img :src="localValues.display.logo" />
                        <button @click="imageRemoved">
                            Delete
                        </button>
                    </div>

                    <FormulateInput
                        v-else
                        :value="localValues.display.logo"
                        name="menu-logo"
                        type="image"
                        :label="labels.logo"
                        :uploader="fakeUploader"
                    />
                </div>
                <div class="field">
                    <List
                        :editable="true"
                        :value="menuLinks"
                        :label="labels.links"
                        :object="linksStructure"
                        @list-changed="updateLinks"
                    />
                </div>
            </div>
            <SvgLoader v-else :loading="true" />
        </BaseCard>
    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import { library } from "@fortawesome/fontawesome-svg-core";
    import prepareForm from "@/services/prepare-formdata";
    import {
        faBook,
        faCog,
        faTrash,
        faCheck,
        faSignInAlt,
        faPlus,
        faTimes
    } from "@fortawesome/free-solid-svg-icons";
    library.add(faBook, faCog, faTrash, faCheck, faSignInAlt, faPlus, faTimes);

    export default {
        name: 'MenuDisplay',
        components: {
            HeaderLabel: () => import('@/components/Labels/HeaderLabel'),
            BaseCard: () => import('@/components/Cards/BaseCard'),
            List: () => import('@/components/List/List'),
            SvgLoader: () => import('@/components/Preloaders/SvgLoader')
        },
        data() {
            return {
                menuTitle: 'Menu | Display settings',
                localValues: {
                    display: {
                        logo: '',
                        links: [],
                        copyright: ''
                    }
                }
            }
        },
        mounted() {
            if (!Object.keys(this.singleEntity).length) this.getMenuData();
        },
        methods: {
            ...mapActions('notification', ['addNotification']),
            ...mapActions('menu', ['getMenuForEditor', 'updateMenu']),
            updateLinks(updatedLinks) {
                let payload = [];

                updatedLinks.forEach(listItem => {
                    payload.push({
                        text: listItem.text.value?.length ? listItem.text.value : '',
                        url: listItem.url.value?.length ? listItem.url.value : ''
                    });
                });

                this.localValues.display.links = payload;
            },
            getMenuData() {
                this.getMenuForEditor( { id: this.$route.params.id } ).then( res => {
                    if (res.success) {
                        this.localValues.display = JSON.parse(JSON.stringify(this.singleEntity.display));
                    } else if (res.error) {
                        this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
                    }
                })
                .catch( err => console.log( err ) );
            },
            saveMenu() {
                console.log('Preparing form with:', this.localValues);
                this.updateMenu(
                    prepareForm({ payload: this.localValues, id: this.$route.params.id })
                ).then((res) => {
                    if (res.success) {
                        this.addNotification({
                            variant: "success",
                            msg: this.labels.object_has_been_updated,
                        });

                        this.getMenuData();
                    } else if (res.error) {
                        this.addNotification({
                            variant: "danger",
                            msg: res.error,
                            labels: self.labels
                        });
                    }
                });
            },
            async fakeUploader( image ) {
                this.localValues.display.logo = image;
                return Promise.resolve( { } );
            },
            imageRemoved() {
                this.localValues.display.logo = '';
            }
        },
        computed: {
            ...mapState("helper", ["admin_roles"]),
            ...mapState("menu", ["singleEntity","openMenuItems"]),
            ...mapGetters("helper", ["hasPermissions"]),
            ...mapGetters("helper", ["labels"]),
            labelButtons() {
                return [
                    {
                        label: this.labels.menu,
                        icon: "",
                        show: true,
                        link: this.$router.resolve({
                            name: 'single_menu',
                            params: this.$route.params
                        }).href,
                        disabled: false,
                        class: "",
                        titleAttribute: this.labels.menu,
                    },
                    {
                        label: 'Display Test',
                        icon: "",
                        show: true,
                        disabled: false,
                        class: "current",
                        titleAttribute: 'Display',
                    }
                ];
            },
            buttonsList() {
                return [
                    {
                        label: this.labels.save,
                        icon: "",
                        variant: "outline-secondary",
                        show: true,
                        action: this.saveMenu,
                        disabled: false,
                    },
                    {
                        label: "",
                        icon: "cog",
                        variant: "outline-secondary",
                        show: true,
                        action: () => {
                            this.$router.push({
                                name: "single_menu_settings",
                                params: {
                                    id: this.$route.params.id,
                                }
                            });
                        },
                        disabled: false,
                        titleAttribute: this.labels.general_settings
                    },
                ];
            },
            linksStructure() {
                return {
                    text: {
                        datatype: 'text',
                        label: this.labels.text_of_link,
                        optional: false
                    },
                    url: {
                        datatype: 'text',
                        label: this.labels.url,
                        optional: false
                    }
                }
            },
            menuLinks() {
                let result = {};

                for (let i = 0; i < this.localValues.display.links.length; i++) {
                    const link = this.localValues.display.links[i];
                    
                    result[i] = {
                        text: {
                            ...this.linksStructure.text,
                            value: link.text
                        },
                        url: {
                            ...this.linksStructure.url,
                            value: link.url
                        }
                    }
                }

                return result;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .menu-display {
        .fields {
            display: flex;
            flex-direction: column;

            .field {
                .preview {
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    label {
                        display: inline-block;
                        font-size: 1rem;
                        margin: 0 0 10px;
                        font-weight: bold;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        object-fit: contain;
                        object-position: center;
                    }

                    button {
                        border: 0px;
                        outline: none;
                        color: #fff;
                        font-weight: bold;
                        font-size: 1rem;
                        background-color: red;
                        border-radius: 5px;
                        padding: 2px 15px;

                        &:focus,
                        &:focus-visible,
                        &:focus-within,
                        &:active,
                        &:target {
                            outline: none;
                            border: 0px;
                        }

                        position: absolute;
                        top: 0;
                        right: 0;
                        margin: auto;
                    }
                }

                &:first-of-type {
                    margin-left: auto;
                    margin-right: auto;
                    width: 550px;
                }

                &:not(:last-child) {
                    margin-bottom: 15px;
                }

                ::v-deep {
                    label {
                        display: inline-block;
                        font-size: 1rem;
                        margin: 0 0 10px;
                        font-weight: bold;
                    }

                    .formulate-input-upload-area {
                        width: 550px;
                        height: 250px;
                    }

                    .formulate-input-element {
                        max-width: unset;
                    }
                }
            }
        }
    }
</style>